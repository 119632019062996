import Component from '@ember/component';
import { action } from '@ember-decorators/object';
import {
  validatePresence,
  validateLength,
  validateInclusion
} from 'ember-changeset-validations/validators';
import lookupValidator from 'ember-changeset-validations';
import { inject as service } from '@ember-decorators/service';
import { task } from 'ember-concurrency-decorators';
import firebase from 'firebase/app';
import Changeset from 'ember-changeset';

export default class AccountRegister extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  @service() router;
  @service() firebaseApp;
  @service() session;
  @service() store;

  constructor() {
    super(...arguments);
    this.changeset = new Changeset(this.userRegister, this.registerValidation, this.registerValidation);
  }

  errorMessage = '';

  isLoading = false;

  userRegister = {
    username: '',
    password: '',
    passwordwdh: '',
    token: '',
    online: null,
    sms: '',
    phonenumber: '',
    firstname: '',
    lastname: '',
    plz: '',
    city: '',
    street: '',
    clientemail: '',
    dataprivacy: '',
    mailfromvita1: false,
    mailfromvita2: false,
    mailfromvita3: false
  };

  registerValidation = lookupValidator({
    nummer: [
      validatePresence({ presence: true, message: 'Bitte geben Sie den Code ein, welchen Sie von Ihrem Arzt erhalten haben' })
    ],
    password: [
      validatePresence({ presence: true, message: 'Bitte geben Sie ein Passwort ein' })
    ],
    passwordwdh: [
      validatePresence({ presence: true, message: 'Bitte geben Sie ein Passwort ein' })
    ],

    dataprivacy: [

    ]
  });

  
  @task
  *signup(userRegister) {

    try {
      this.loading = true;
      let user, authtoken;
      let users = yield this.store.query('user', {
        filter: {
          nummer: userRegister.get('token'),
        }
      })

  
 
      if (users.length === 0) {
        this.set('errorMessage', 'Benutzer existiert bereits oder die Patientennummer wurde nicht gefunden.')
        this.loading = false;
        return;
      }

      let tokens = yield this.store.query('authtoken', {
        filter: {
          token: userRegister.get('token')
        }
      })

      authtoken = tokens.firstObject;
    
      if (authtoken.token !== userRegister.get('token')) {
        this.set('errorMessage', 'Dieser Code ist uns nicht bekannt oder wurde bereits verwendet.')
        this.loading = false;
        return;
      }


    
      const auth = yield this.firebaseApp.auth();

      let username = userRegister.get('username');
      let nummer = userRegister.get('token');
      if (!username) {
        username = `p-${nummer}@meinevitabilanz.de`;
      }

      let users2 = yield this.store.query('user', {
        filter: {
          nummer: userRegister.get('token')
        }
      })
      let newUser = users2.firstObject;
      newUser.set('email', username);
      yield newUser.save();
      let success = yield auth.createUserWithEmailAndPassword(username, userRegister.get('password')).catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(error)
        return error;
        
      });
      if (success.code == 'auth/weak-password') {
        this.set('error', "Das Passwort sollte mindestens 6 Zeichen enthalten");
        return;
      }
    
      console.log(success);
      this.router.transitionTo('account.login');
      this.loading = false;
    } catch(e) {
      this.loading = false;
      console.error(e);
    }
    
 
  }
};
