import DS from 'ember-data';
const { Model } = DS;
import { attr } from '@ember-decorators/data';

export default class AddressModel extends Model {
  @attr('string') city;
  @attr('string') street;
  @attr('string') zip;
  @attr('string') country;
}
