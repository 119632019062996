import Route from '@ember/routing/route';

export default class SeedRoute extends Route {

    model() {
       /* this.store.createRecord('authtoken', {token: 'code10'}).save();
        this.store.createRecord('authtoken', {token: 'code11'}).save();
        this.store.createRecord('authtoken', {token: 'code12'}).save();
        this.store.createRecord('authtoken', {token: 'code13'}).save();
        this.store.createRecord('authtoken', {token: 'code14'}).save();
        this.store.createRecord('authtoken', {token: 'code15'}).save();
        this.store.createRecord('authtoken', {token: 'code16'}).save();
        this.store.createRecord('authtoken', {token: 'code17'}).save();
        this.store.createRecord('authtoken', {token: 'code18'}).save();
        this.store.createRecord('authtoken', {token: 'code19'}).save();
        this.store.createRecord('authtoken', {token: 'code20'}).save();
        this.store.createRecord('authtoken', {token: 'code21'}).save();
        this.store.createRecord('authtoken', {token: 'code22'}).save();
        this.store.createRecord('authtoken', {token: 'code23'}).save();
        this.store.createRecord('authtoken', {token: 'code24'}).save();
        this.store.createRecord('authtoken', {token: 'code25'}).save();
        this.store.createRecord('authtoken', {token: 'code26'}).save();
        this.store.createRecord('authtoken', {token: 'code27'}).save();
        this.store.createRecord('authtoken', {token: 'code28'}).save();
        this.store.createRecord('authtoken', {token: 'code29'}).save();
        this.store.createRecord('authtoken', {token: 'code30'}).save();
        this.store.createRecord('authtoken', {token: 'code31'}).save();
        this.store.createRecord('authtoken', {token: 'code32'}).save();
        this.store.createRecord('authtoken', {token: 'code33'}).save();
        this.store.createRecord('authtoken', {token: 'code34'}).save();
        this.store.createRecord('authtoken', {token: 'code35'}).save();
        this.store.createRecord('authtoken', {token: 'code36'}).save();
        this.store.createRecord('authtoken', {token: 'code37'}).save();
        this.store.createRecord('authtoken', {token: 'code38'}).save();
        this.store.createRecord('authtoken', {token: 'code39'}).save();
        this.store.createRecord('authtoken', {token: 'code40'}).save();*/


        [{
            link: 'https://www.therakey.de/typ2/intensiv/was-kann-ich-tun/erfolgsfaktor-nichtrauchen/grundlagen-nichtrauchen.html',
            title: 'Erfolgsfaktor Nichtrauchen',
            slot: "WUNSCH_RAUCHEN",
            rule: {
                value: "aufgeben"
            }
        },
        {
            link: 'https://www.therakey.de/typ2/intensiv/was-kann-ich-tun/erfolgsfaktor-ernaehrung/basiswissen-ernaehrung/was-kann-ernaehrung-bewirken.html',
            title: 'Was kann Ernährung bewirken',
            slot: "WUNSCH_DIABETES",
            rule: {
                value: "besser einstellen"
            }
        },
        {
            link: 'https://www.therakey.de/typ2/intensiv/was-kann-ich-tun/erfolgsfaktor-ernaehrung/basiswissen-ernaehrung/der-perfekte-teller.html',
            title: 'Der perfekte Teller',
            slot: "WUNSCH_ERNAEHRUNG",
            rule: {
                value: "verbessern"
            }
        },
        {
            link: 'https://www.therakey.de/typ2/intensiv/was-kann-ich-tun/erfolgsfaktor-bewegung/bewegung-bei-typ-2-diabetes.html',
            title: 'Bewegung bei Typ-2-Diabetes',
            slot: "WUNSCH_FITNESS",
            rule: {
                value: "verbessern"
            }
        },
        {
            link: 'https://www.therakey.de/typ2/intensiv/was-kann-ich-tun/erfolgsfaktor-bewegung/bewegung-was-wie/bewegung-im-alltag.html',
            title: 'Bewegung im Alltag',
            slot: "MEHRSPORTMOEGLICH",
            rule: {
                value: "ja"
            }
        },
        {
            link: 'https://www.therakey.de/typ2/intensiv/was-kann-ich-tun/erfolgsfaktor-koerpergewicht/koerpermasse-berechnen.html',
            title: 'Körpermaße berechnen',
            slot: "DIAETWUNSCH",
            rule: {
                value: "ja"
            }
        }
        ].forEach(link=>{
            
        })
        
    }
}
