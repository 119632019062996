import Controller from '@ember/controller';
import {
  inject as service
} from '@ember-decorators/service';
import {
  get
} from '@ember/object';
export default class ApplicationController extends Controller {

  @service()
  store;

  @service()
  firebaseApp;

  actions = {
    async createModel(event) {
    
    }
  }

}
