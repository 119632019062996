import Component from "@ember/component";
import { action } from "@ember-decorators/object";
import { get, defineProperty } from "@ember/object";
import { tagName } from "@ember-decorators/component";
import { computed } from "@ember-decorators/object";

@tagName("")
export default class AppQuestionnaireQuestion extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  @computed("answer.answer")
  get isSelected() {
    if (this.answer === undefined) {
      return false;
    }
    if (this.get("answer.answer") === undefined) {
      return false;
    }
    if (this.get("answer.answer").indexOf(this.option) !== -1) {
      return true;
    }
  }

  @action
  answerQuestion(question, option, answer) {
    this.onClose();
    this.onAnswer(question, option, answer);
    return false;
  }
}
