import Route from "@ember/routing/route";
import moment from "moment";

export default class DashboardBerichte extends Route.extend({
  // anything which *must* be merged to prototype here
}) {
  async model() {
    let user = this.modelFor("dashboard").user;

    let reports = await this.store.query("report", {
      filter: {
        user: user.id,
      },
    });
    reports.map((r) => {
      return { ...r, createdts: moment(r.created).unix() };
    });
    reports = reports.sortBy("sortCreated").reverse();
    return reports;
  }
}
