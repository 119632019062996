import DS from 'ember-data';
const {
  Model
} = DS;
import {
  belongsTo,
  attr
} from '@ember-decorators/data';

export default class ClientModel extends Model {
  @belongsTo('file') logo;
  @attr('object') address;
  @belongsTo('user') admin;
  @attr('string') email;
  @attr('string') name;
  @attr('string') level;
}
