import Route from '@ember/routing/route';
import {
  inject as service
} from '@ember-decorators/service';
export default class AccountLogin extends Route.extend({
  // anything which *must* be merged to prototype here
}) {
  @service() firebaseApp;

  async beforeModel() {
    //await this.store.unloadAll();
    localStorage.setItem("nummer", null);

    const auth = await this.firebaseApp.auth();


  
    return true;
  }
}
