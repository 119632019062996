import Route from "@ember/routing/route";
import { inject as service } from "@ember-decorators/service";

import firebase from "firebase/app";
import { task, enqueueTask } from "ember-concurrency-decorators";
import { timeout } from "ember-concurrency";

import { isEmpty } from "@ember/utils";

//firebase.firestore.setLogLevel('debug')

export default class ApplicationRoute extends Route {
  @service()
  firebaseApp;
  @service()
  client;

  async model() {
    let project;
    if (window.location.hostname == "app-vitabilanz.de") {
      this.transitionTo("account.reg");
      return;
    }
    if (window.location.hostname == "localhost") {
      project = await this.store.query("project", {
        filter: {
          appurl: "meinevitabilanz.de",
        },
      });
    } else {
      project = await this.store.query("project", {
        filter: {
          appurl: window.location.hostname,
        },
      });
    }

    return project.firstObject;
  }
  async afterModel(model, transition) {
    if (window.location.hostname == "app-vitabilanz.de") {
      this.transitionTo("account.reg");
      return;
    }
    const client = await model.client;
    this.set("client.client", client);
    const auth = await this.firebaseApp.auth();
    //   await auth.signOut()
    //     .then(data => console.log(data))wie isn die jetzt dioe url?=
    
    //     .catch(err => console.log(err));
    this.store.adapterFor("answer").set("namespace", `client/${client.name}`);
    this.store.adapterFor("report").set("namespace", `client/${client.name}`);
    this.store.adapterFor("user").set("namespace", `client/${client.name}`);
    this.store
      .adapterFor("authtoken")
      .set("namespace", `client/${client.name}`);
    this.store.adapterFor("link").set("namespace", `client/${client.name}`);

    /*
    await auth.signInAnonymously().catch(function(error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // ...
    });*/

    /*let reports = [];
    let collection = await (await this.firebaseApp.firestore())
      .collection("client/arburg/reports")
      .get();
    console.log(reports);
    collection = collection.forEach((doc) => {
      let docData = {
        ...doc.data(),
        time: moment(doc.data().created).format("MM"),
      };
      reports.pushObject(docData);
    });
    console.log(reports.length);
    console.log(reports.filterBy("time", "10"));
    console.log(reports.filterBy("time", "09"));
    reports = reports.filter((r) => {
      console.log(r.time);
      return ["10", "09"].includes(r.time);
    });

    let reportData = {};
    reports.forEach((doc) => {
      let answers = doc.answers;

      answers.forEach((a) => {
        let key = `${a.text}---${a.answer}`;
        if (!reportData[key]) {
          reportData[key] = 1;
        } else {
          reportData[key] = reportData[key] + 1;
        }
      });
    });
    let csv = "";
    Object.keys(reportData).forEach((key) => {
      csv += `"${key}", "${reportData[key]}" \n`;
    });
    console.log(csv);
*/
    let storageRef = await this.firebaseApp.storage();
    storageRef = storageRef.ref();
    let logoUrl = await storageRef
      .child(`logos/${client.name}.png`)
      .getDownloadURL();
    this.set("client.logoUrl", logoUrl);

    await this.ensureLogin(transition);
  }

  async ensureLogin(transition) {
    let filter, users, user;
    const auth = await this.firebaseApp.auth();
    const currentUser = auth.currentUser;

    const number = window.localStorage.getItem("nummer");

    if (currentUser !== null) {
      filter = {
        email: currentUser.email,
      };
    } else if (number !== null) {
      filter = {
        nummer: number,
      };
    } else {
      filter = {
        authtoken: window.localStorage.getItem("nummer"),
      };
    }
    users = await this.store.query("user", {
      filter: filter,
    });

    if (users.length > 0) {
      user = users.firstObject;
    } else {
      user = null;
    }

    if (
      user === null &&
      transition.intent.url !== "/account/admin" &&
      transition.intent.url !== "/lexicon"
    ) {
      this.transitionTo("account.login");
    }
  }
}
