import Route from '@ember/routing/route';

export default class LexiconRoute extends Route {
  async model() {
    let result = await fetch(`https://vitabilanz-pdf.staging-netzreich.de/index.php?mode=4`, {
      method: 'POST',
      headers: {
        'Accept': 'application/pdf',
        'Content-Type': 'application/pdf'
      }
    });
    let blob = await result.blob();
    let newBlob = new Blob([blob], {
      type: "application/pdf"
    })

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    // For other browsers: 
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    let link = document.createElement('a');
    link.href = data;
    link.download = "Lexikon.pdf";
    link.click();
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
    }, 100);
  }
}
