import Route from '@ember/routing/route';
import { set } from '@ember/object';
export default class DashboardUebersichtDetailRoute extends Route {
    async model(params) {
        let model = this.modelFor('dashboard')
        let report = await this.store.findRecord('report', params.report_id);
        
        set(model, 'report', report);
     
        return model;
    }
}
