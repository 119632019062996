import DS from 'ember-data';
const {
  Model
} = DS;
import {
  attr,
  belongsTo,
  hasMany
} from '@ember-decorators/data';

export default class ProjectModel extends Model {
  @attr('string') name;
  @belongsTo('file') logo;
  @attr('object') address;
  @attr('string') email;
  @attr('string') appurl;
  @attr('string') mode;
  @attr('string') snippet;
  @attr('string') level;
  @attr('boolean', {
    defaultValue: true
  }) showlogo;
  //@hasMany('link') links;
  @belongsTo('client') client;
}
