import DS from 'ember-data';
const { Model } = DS;
import { attr } from '@ember-decorators/data';

export default class LinkModel extends Model {
  @attr('string') link;
  @attr('string') slot;
  @attr('object') rule;
  @attr('object') location;
  @attr('string') title;
}
