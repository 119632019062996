import Component from '@ember/component';
import { action } from '@ember-decorators/object';
import {
  validatePresence,
  validateLength,
  validateConfirmation
} from 'ember-changeset-validations/validators';
import lookupValidator from 'ember-changeset-validations';
import { inject as service } from '@ember-decorators/service';
import { task } from 'ember-concurrency-decorators';


export default class AccountPassword extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  @service() router;
  @service() firebaseApp;
  @service() session;
  @service() store;

  mode = 0;


  userData = {
    email: '',
    newPassword: '',
    code: '',
  };

  registerValidation = lookupValidator({
    username: [
      validateLength({ min: 6, message: 'Ihr Benutzername/E-mail sollte mindestens 6 Zeichen lang sein' })
    ],

    nummer: [
      validatePresence({ presence: true, message: 'Bitte geben Sie den Code ein, welchen Sie von Ihrem Arzt erhalten haben' })
    ]
  });



  @task
  *newPassword(userData) {
    try {
      console.log("test3")
      
        const auth = yield this.firebaseApp.auth();
        yield auth.sendPasswordResetEmail(
        userData.get('email')); 
        this.set('mode', 'feedback');
        console.log("test2")
      } catch(e) {
        this.set('mode', 'feedback');
        console.error(e);
      }
  }
  @task
  *resetPassword(userData) {
   
      try {
        const auth = yield this.firebaseApp.auth();
        
        yield auth.confirmPasswordReset(this.get('oobCode'), userData.get('newPassword'));
        
        this.router.transitionTo('account.login');
      } catch(e) {
        console.error(e);
      }
  }

  
};
