import DS from 'ember-data';
const { Model } = DS;
import { attr } from '@ember-decorators/data';

export default class FileModel extends Model {
  @attr('string') bucket;
  @attr('string') cacheControl;
  @attr('string') contentDisposition;
  @attr('string') contentEncoding;
  @attr('string') contentLanguage;
  @attr('string') contentType;
  @attr('string') fullPath;
  @attr('string') generation;
  @attr('string') md5Hash;
  @attr('string') metageneration;
  @attr('string') name;
  @attr('number') size;
  @attr('string') timeCreated;
  @attr('string') type;
  @attr('string') updated;
}
