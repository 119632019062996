import Controller from "@ember/controller";
import { task, enqueueTaskGroup } from "ember-concurrency-decorators";
import { timeout } from "ember-concurrency";
import { inject as service } from "@ember-decorators/service";
import {action} from '@ember-decorators/object'
export default class ExportController extends Controller {
  @enqueueTaskGroup({ maxConcurrency: 3 })
  reportTasks;

  @service()
  firebaseApp;

  questionCache = {};
  show = false;

  result = {
    PSYCHE: {
      lt15: 0,
      gte15: 0,
    },
    HERZKREISLAUFRISIKO: {
      lt5: 0,
      gte5: 0,
    },
    WUNSCH_ERNAEHRUNG: {},
    WUNSCH_RAUCHEN: {},
    WUNSCH_FITNESS: {},
    WUNSCH_SEELE: {},
    WUNSCH_SOZIAL: {},
    WUNSCH_ARBEIT: {},
    WUNSCH_VORSORGE: {},
    WUNSCH_PFLEGE: {},
    DIABETESRISIKO: {},
  };
  async build(report) {

    let answers = await report.get('answers');

    let user = await report.user;
    let input = [];
    let project;

    if (window.location.hostname == "localhost") {
      project = await this.store.query("project", {
        filter: {
          appurl: "meinevitabilanz.de",
        },
      });
    } else {
      project = await this.store.query("project", {
        filter: {
          appurl: window.location.hostname,
        },
      });
    }
    input.pushObjects([
      {
        id: "1",
        question: "GESCHLECHT",
        value: user.gender == "Männlich" ? "0" : "1",
      },
      {
        id: "1",
        question: "ALTER",
        value: user.realage.toString(),
      },
      {
        id: "1",
        question: "mandant",
        value: project.firstObject.snippet,
      },
    ]);
    await Promise.all(
      answers.map(async (element) => {
        let question = await element.question;
        if (question == null) {
          return;
        }
        let inputData = {
          id: "",
          question: question.field,
          value: element.answer,
        };
        return input.pushObject(inputData);
      })
    );
    console.log("INOUT LENGTH", input.length);
    input = input.uniqBy("question");
    console.log("INOUT LENGTH", input.length);

    let links = await this.store.findAll("link");
    let linkArray = links.toArray().map((item) => {
      return item.serialize();
    });

    let labor = input.filter((i) => {
      console.log(i.question);
      return [
        "NUECHTERNBZ",
        "GESAMTCHOLESTERIN",
        "HBA1C",
        "HDLCHOLESTERIN",
        "LDLCHOLESTERIN",
        "TRIGLYCERIDE",
        "HARNSAEURE",
        "KREATININ",
        "LEBERGGT",
        "LEBERGPT",
      ].includes(i.question);
    }).length;
    console.log("labor", labor);
    return {
      linkArray,
      labor,
      input
    }
  }

  @task({ group: "reportTasks" })
  *generateReport2(report) {
    let reports = yield this.store.findAll("report");

    reports = reports.filter((r)=> {

      if (moment('2021-12-31').isBefore(moment(r.created))) {
        return true;
      };
      return false;
    })
    console.log(reports.length)
  }
  @task({ group: "reportTasks" })
  *generateReport(report) {
    try {
      let input = [];
      let answers = yield report.answers;
      let user = yield report.user;
      let reports = yield this.store.findAll("report");
      console.log(user);
      user = yield this.store.findRecord("user", user);

      input.pushObjects([
        {
          id: "1",
          question: "GESCHLECHT",
          value: user.gender == "Männlich" ? "0" : "1",
        },
        {
          id: "1",
          question: "ALTER",
          value: user.realage.toString(),
        },
      ]);
      yield Promise.all(
        answers.map(async (element) => {
          let question = await element.question;
          if (this.questionCache[question]) {
            question = this.questionCache[question];
          } else {
            question = await this.store.findRecord("question", question);
            this.questionCache[question] = question;
          }
          if (question == null) {
            return;
          }
          let inputData = {
            id: "",
            question: question.field,
            value: element.answer,
          };
          return input.pushObject(inputData);
        })
      );
      input = input.uniqBy("question");

      let oldReportIndex = reports.indexOf(report);

      let oldinput;
      if (oldReportIndex <= reports.length) {
        const oldReport = yield reports.objectAt(`${oldReportIndex+1}`)
        if (oldReport) {
          let data = yield this.build(reports.objectAt(`${oldReportIndex+1}`));
          oldinput = data.input;
        }
   
      }

        console.log({
          user: user.nummer,
          firstname: report.user.firstname,
          lastname: report.user.lastname,
          date: moment(report.created).format("DD.MM.YYYY"),
          labor: "1",
          internet: user.online ? "1" : "0",
          export: {},
          links: [],
          answers: [...input],
        })
      let result = yield fetch(
        "https://vitabilanz-pdf.staging-netzreich.de/index.php?mode=10",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user: user.nummer,
            firstname: report.user.firstname,
            lastname: report.user.lastname,
            date: moment(report.created).format("DD.MM.YYYY"),
            labor: "1",
            internet: user.online ? "1" : "0",
            export: {},
            links: [],
            answers: [...input],
            "oldreport": {
              "answers": oldinput
            }
          }),
        }
      );
      let exportData = yield result.json();
      if (exportData.HERZKREISLAUFRISIKO.valueAsInt >= 5) {
        this.result.HERZKREISLAUFRISIKO.gte5++;
      } else {
        this.result.HERZKREISLAUFRISIKO.lt5++;
      }
      if (!this.result.DIABETESRISIKO[exportData.DIABETESRISIKO.initValue]) {
        this.result.DIABETESRISIKO[exportData.DIABETESRISIKO.initValue] = 0;
      }
      this.result.DIABETESRISIKO[exportData.DIABETESRISIKO.initValue]++;

      if (exportData.PSYCHE.initValue >= 15) {
        this.result.PSYCHE.gte15++;
      } else {
        this.result.PSYCHE.lt15++;
      }

      if (!this.result.WUNSCH_RAUCHEN[exportData.WUNSCH_RAUCHEN.initValue]) {
        this.result.WUNSCH_RAUCHEN[exportData.WUNSCH_RAUCHEN.initValue] = 0;
      }
      this.result.WUNSCH_RAUCHEN[exportData.WUNSCH_RAUCHEN.initValue]++;

      if (
        !this.result.WUNSCH_ERNAEHRUNG[exportData.WUNSCH_ERNAEHRUNG.initValue]
      ) {
        this.result.WUNSCH_ERNAEHRUNG[
          exportData.WUNSCH_ERNAEHRUNG.initValue
        ] = 0;
      }
      this.result.WUNSCH_ERNAEHRUNG[exportData.WUNSCH_ERNAEHRUNG.initValue]++;

      if (!this.result.WUNSCH_FITNESS[exportData.WUNSCH_FITNESS.initValue]) {
        this.result.WUNSCH_FITNESS[exportData.WUNSCH_FITNESS.initValue] = 0;
      }
      this.result.WUNSCH_FITNESS[exportData.WUNSCH_FITNESS.initValue]++;

      if (!this.result.WUNSCH_SEELE[exportData.WUNSCH_SEELE.initValue]) {
        this.result.WUNSCH_SEELE[exportData.WUNSCH_SEELE.initValue] = 0;
      }
      this.result.WUNSCH_SEELE[exportData.WUNSCH_SEELE.initValue]++;

      if (!this.result.WUNSCH_SOZIAL[exportData.WUNSCH_SOZIAL.initValue]) {
        this.result.WUNSCH_SOZIAL[exportData.WUNSCH_SOZIAL.initValue] = 0;
      }
      this.result.WUNSCH_SOZIAL[exportData.WUNSCH_SOZIAL.initValue]++;

      if (!this.result.WUNSCH_ARBEIT[exportData.WUNSCH_ARBEIT.initValue]) {
        this.result.WUNSCH_ARBEIT[exportData.WUNSCH_ARBEIT.initValue] = 0;
      }
      this.result.WUNSCH_ARBEIT[exportData.WUNSCH_ARBEIT.initValue]++;

      if (!this.result.WUNSCH_VORSORGE[exportData.WUNSCH_VORSORGE.initValue]) {
        this.result.WUNSCH_VORSORGE[exportData.WUNSCH_VORSORGE.initValue] = 0;
      }
      this.result.WUNSCH_VORSORGE[exportData.WUNSCH_VORSORGE.initValue]++;

      if (!this.result.WUNSCH_PFLEGE[exportData.WUNSCH_PFLEGE.initValue]) {
        this.result.WUNSCH_PFLEGE[exportData.WUNSCH_PFLEGE.initValue] = 0;
      }
      this.result.WUNSCH_PFLEGE[exportData.WUNSCH_PFLEGE.initValue]++;

      let csvLine = {
        nummer: user.nummer,
        age: user.realage.toString(),
        geschlecht: user.gender,
        HERZKREISLAUFRISIKO: exportData.HERZKREISLAUFRISIKO.initValue,
        DIABETESRISIKO: exportData.DIABETESRISIKO.initValue,
        WUNSCH_RAUCHEN: exportData.WUNSCH_RAUCHEN.initValue,
        WUNSCH_ERNAEHRUNG: exportData.WUNSCH_ERNAEHRUNG.initValue,
        WUNSCH_FITNESS: exportData.WUNSCH_FITNESS.initValue,
        WUNSCH_SEELE: exportData.WUNSCH_SEELE.initValue,
        WUNSCH_SOZIAL: exportData.WUNSCH_SOZIAL.initValue,
        WUNSCH_ARBEIT: exportData.WUNSCH_ARBEIT.initValue,
        WUNSCH_PFLEGE: exportData.WUNSCH_PFLEGE.initValue,
        PSYCHE: exportData.PSYCHE.initValue,
      };

      this.csv.pushObject(csvLine);
      
      return;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  csv = [];

  @action
  csv() {
    console.table(this.csv)
  }

  @task({ on: "init" })
  *import() {
    console.log("Start import");
    yield timeout(1000);


    //yield this.generateReport.perform();
    //return

    let reports = [];
    let collection = yield (yield this.firebaseApp.firestore())
      .collection("client/arburg/reports")
      .get();
    collection = collection.forEach((doc) => {
      let docData = {
        ...doc.data(),
        time: moment(doc.data().created).format("YYYY"),
      };
      reports.pushObject(docData);
    });
    console.log(reports.length);
    console.log(reports[0])
    reports = reports.filter((r) => {
      return ["2023"].includes(r.time);
    });
    console.log(reports.length);

    reports.forEach((r) => {
      if (r.answers.length > 0) {
        this.generateReport.perform(r);
      }
    });

    return true;
  }
}
