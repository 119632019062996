import FirestoreAdapter from 'emberfire/adapters/firestore';

export default class ApplicationAdapter extends FirestoreAdapter.extend({
  enablePersistence: true,
  persistenceSettings: {
    synchronizeTabs: true
  }
}) {

}
