// app/serializers/schedule.js
import FirebaseSerializer from 'emberfire/serializers/firestore';

import DS from 'ember-data';


export default FirebaseSerializer.extend(DS.EmbeddedRecordsMixin, {
  attrs: {
    answers: {
      embedded: 'always'
    }
  },
});
