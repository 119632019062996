import Controller from "@ember/controller";
import { service } from "@ember-decorators/service";
import { alias } from "@ember-decorators/object/computed";
import { action } from "@ember-decorators/object";

export default class DashboardUebersicht extends Controller.extend({
  // anything which *must* be merged to prototype here
}) {
  queryParams = ["activeGroup"];
  activeGroup = "1";
}
