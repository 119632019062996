import { helper } from '@ember/component/helper';

export function groupNames(params/*, hash*/) {
  const groupnames = [
    '',
    'Erkrankungen bei Ihnen',
    'Erkrankungen in Ihrer Familie',
    'Impf-Pass',
    'Vorsorgeuntersuchungen bei Ihnen',
    'Körperliche Aktivität',
    'Essen Sie',
    'Diät',
    'Raucher',
    'Schlaf ?',
    'Leiden Sie wiederholt an',
    'Soziale Situation',
    'Nehmen Sie folgende Medikamente ein ?',
    'Wünsche und Ziele für Ihre Gesundheit',
    'Allgemeines Wohlbefinden',
    'Ihre Körpermesswerte',
    'Ihre Laborwerte',
    'Gesundheitsbericht',
  ]
  console.log(params)
  if (params[0] == "15") {
    return "A";
  }
  if (params[0] == "16") {
    return "B";
  }
  return groupnames[parseInt(params[0])];
}

export default helper(groupNames);
