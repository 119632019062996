import Component from '@ember/component';
import {
  task
} from 'ember-concurrency-decorators';
import {
  inject as service
} from '@ember-decorators/service';
import {
  action
} from '@ember-decorators/object';

export default class AdminFunctionsComponent extends Component {

  @service('store') store;

  patientennummer = null;

  user = null;

  firstname = null;
  lastname = null;
  activeReport = null;
  isLoading = false;

  @action
  setActiveReport(report) {
    this.set('activeReport', report);
  }

  @task
    *
    loadUser() {
      let users = yield this.store.query('user', {
        filter: {
          nummer: this.patientennummer
        }
      });
      this.set('user', users.firstObject);
      this.loadReports.perform();
    }

  @task
    *
    loadReports() {
      let reports = yield this.store.query('report', {
        filter: {
          user: this.user.id
        }
      });
      reports = reports.sortBy("sortCreated").reverse();
      this.set('reports', reports);
    }
    async build(report) {

      let answers = await report.get('answers');
  
      let user = await report.user;
      let input = [];
      let project;
  
      if (window.location.hostname == "localhost") {
        project = await this.store.query("project", {
          filter: {
            appurl: "meinevitabilanz.de",
          },
        });
      } else {
        project = await this.store.query("project", {
          filter: {
            appurl: window.location.hostname,
          },
        });
      }
      input.pushObjects([
        {
          id: "1",
          question: "GESCHLECHT",
          value: user.gender == "Männlich" ? "0" : "1",
        },
        {
          id: "1",
          question: "ALTER",
          value: user.realage.toString(),
        },
        {
          id: "1",
          question: "mandant",
          value: project.firstObject.snippet,
        },
      ]);
      await Promise.all(
        answers.map(async (element) => {
          let question = await element.question;
          if (question == null) {
            return;
          }
          let inputData = {
            id: "",
            question: question.field,
            value: element.answer,
          };
          return input.pushObject(inputData);
        })
      );
      console.log("INOUT LENGTH", input.length);
      input = input.uniqBy("question");
      console.log("INOUT LENGTH", input.length);
  
      let links = await this.store.findAll("link");
      let linkArray = links.toArray().map((item) => {
        return item.serialize();
      });
  
      let labor = input.filter((i) => {
        console.log(i.question);
        return [
          "NUECHTERNBZ",
          "GESAMTCHOLESTERIN",
          "HBA1C",
          "HDLCHOLESTERIN",
          "LDLCHOLESTERIN",
          "TRIGLYCERIDE",
          "HARNSAEURE",
          "KREATININ",
          "LEBERGGT",
          "LEBERGPT",
        ].includes(i.question);
      }).length;
      console.log("labor", labor);
      return {
        linkArray,
        labor,
        input
      }
    }
  @task
    *
    loadReport() {
      this.set('isLoading', true);
      let reports = yield this.get('reports');
      let report = this.activeReport;
      let answers = yield report.get('answers');
      let user = yield report.user;
      let project = yield this.store.query('project', {
        filter: {
          appurl: window.location.hostname
        }
      });
      let input = [];
      input.pushObjects([{
          "id": "1",
          "question": "GESCHLECHT",
          "value": user.gender == 'Männlich' ? '0' : '1'
        },
        {
          "id": "1",
          "question": "ALTER",
          "value": user.realage.toString()
        },
        {
          "id": "1",
          "question": "mandant",
          "value": project.firstObject.snippet

        }
      ]);
      yield Promise.all(answers.map(async (element) => {
        let question = await element.question;
        if (question == null) {
          return;
        }
        let inputData = {
          id: '',
          question: question.field,
          value: element.answer
        };
        return input.pushObject(inputData);
      }));

      let oldReportIndex = reports.indexOf(report);

      let oldinput;
      if (oldReportIndex <= reports.length) {
        const oldReport = yield reports.objectAt(`${oldReportIndex+1}`)
        if (oldReport) {
          let data = yield this.build(reports.objectAt(`${oldReportIndex+1}`));
          oldinput = data.input;
        }
   
      }

      let links = yield this.store.findAll('link');
      let linkArray = links.toArray().map(item => {
        return item.serialize();
      })


      let result = yield fetch('https://vitabilanz-pdf.staging-netzreich.de/index.php?mode=1', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "user": this.patientennummer,
          "firstname": this.firstname,
          "lastname": this.lastname,
          "date": moment(report.created).format('DD.MM.YYYY'),
          "export": {},
          "links": linkArray,
          "answers": input,
          "oldreport": {
            "answers": oldinput
          }
        })
      });
      let blob = yield result.blob();
      let newBlob = new Blob([blob], {
        type: "application/pdf"
      })

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }

      // For other browsers: 
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);
      let link = document.createElement('a');
      link.href = data;
      link.download = "Bericht.pdf";
      this.set('isLoading', false);
      link.click();
      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL

        window.URL.revokeObjectURL(data);
      }, 100);
    }
}
