import Component from "@ember/component";
import { computed, action, observes } from "@ember-decorators/object";
import { inject as service } from "@ember-decorators/service";
import { uuid } from "ember-cli-uuid";
import { set } from "@ember/object";
import { copy } from "@ember/object/internals";
import { tagName } from "@ember-decorators/component";
import { alias } from "@ember-decorators/object/computed";
import { run } from "@ember/runloop";
@tagName("")
export default class AppQuestionnaire extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  @service store;

  @alias("questions") model;

  true = true;
  false = false;

  nextQuestion;
  groupSorting;

  didReceiveAttrs() {
    let userid = this.user.id.toUpperCase();

    this.set("letter1", userid.charAt(0));
    this.set("letter2", userid.charAt(1));
    this.set("letter3", userid.charAt(2));
    this.set("letter4", userid.charAt(3));
    this.set("letter5", userid.charAt(4));
    this.set("letter6", userid.charAt(5));
  }

  firstQuestionToAnswer = null;

  @action
  async nextGroup(group) {
    console.log("next group", group, this.get("groups"));
    let index = (await this.get("groups")).indexOf(group);
    let newGroup = (await this.get("groups"))[index + 1];
    this.set("activeGroup", newGroup);
  }

  @action
  onFirstQuestionWithoutAnswer(question) {
    let answer = this.report.get('answers').findBy("question", question);
    if (this.firstQuestionToAnswer !== null) {
      return false;
    }
  }
  @observes('answers.[]', 'answers.@each.answer')
  onFirstQuestionWithoutAnswerObserver(...args) {

    return true;
  }

  @computed("groupSorting", "answers", "answers.[]")
  get answerCount() {
    return (async () => {
      let count = {};
      let answers = await this.answers;
      let questions = await this.questions;

      this.groupSorting.forEach((group) => {
        let countA = answers.filterBy("question.group", group);
        let countQ = questions.filterBy("group", group);

        if (countA.length === countQ.length) {
          count[group] = true;
          let indexOfGroup = this.groupSorting.indexOf(group);

          if (countQ.firstObject.group === this.activeGroup) {
            document.getElementsByClassName("container-scroll")[0].scrollTo({
              top: 100,
              left: 100,
            });
          }
        }
      });

      this.set("count", count);
    })();
  }

  @computed("model")
  get groups() {
    return (async () => {
      let groups = [];
      let questions = await this.questions;
      questions.forEach((element) => {
        if (!groups.includes(element.group)) {
          groups.pushObject(element.group);
        }
      });

      return groups;
    })();
  }
  scrollTo(element, to, duration) {
    let start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    let animateScroll = function () {
      currentTime += increment;
      let val = Math.easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  }
  @action
  changeGroup(group) {
    this.set("activeGroup", group);
    if (window.innerWidth < 500) {
      run.next(this, () => {
        console.log(
          `scroll element to `,
          document.getElementsByClassName("question")[0].offsetTop
        );
        this.scrollTo(
          document.getElementsByClassName("container-scroll")[0],
          document.getElementsByClassName("question")[0].offsetTop,
          500
        );
      });
    }
  }

  @action
  async answerQuestion(question, option, answer) {
    
    //this.set("firstQuestionToAnswer", null);

    let questions = await this.questions;
    let indexOfQuestion = questions.indexOf(question);
    let nextQuestion = questions.objectAt(indexOfQuestion + 1);
    if (nextQuestion !== undefined && option!== "Nicht berufstätig") {
      this.set("nextQuestion", nextQuestion);
    }
 
    let tempanswer = {
      question: question,
      text: question.questiontext,
      answer: option,
      report: this.report,
      user: this.user,
    };
    console.log(tempanswer);
    let result;

    if (answer === undefined) {
    } else {
      answer.setProperties(tempanswer);
    }
   

    if (answer.id == null) {
      let id = uuid();
      console.log(id);
      answer.set("id", id);
    }

    let answers = await this.report.get('answers');
    answers.addObject(answer);
    let resp = await this.report.save();
    this.set('activeGroup', nextQuestion.group);

    return resp;
  }
}
