import Component from '@ember/component';
import {
  action
} from '@ember-decorators/object';
import {
  get,
  defineProperty
} from '@ember/object';
import {
  tagName
} from '@ember-decorators/component';
import {
  isEmpty,
  typeOf
} from '@ember/utils';
import {
  computed
} from '@ember-decorators/object';
import {
  inject as service
} from '@ember-decorators/service';
import {
  run
} from '@ember/runloop';

@tagName('div')
export default class AppQuestionnaireQuestion extends Component.extend({
  // anything which *must* be merged to prototype here
}) {

  @service()
  store;

  isSelected = false;

  didReceiveAttrs() {
    if (this.answer == null) {
      return;
    }
    if (this.answer.answer === this.option) {
      this.set('isSelected', true);
    }
    if (this.answer === undefined) {
      this.set('answer', this.store.createRecord('answer', {
        answer: ''
      }));
    }

  }
  didInsertElement() {
    this.focusInput();
  }

  focusInput() {
    console.log("Insert new Element", this.element.getElementsByTagName('input')[0])
    this.element.getElementsByTagName('input')[0].focus();
  }

  @action
  clearInput(question, option, answer) {

    answer.set('answer', '');
    this.onInfo(``);
    this.onClose();
    this.onAnswer([question, '', answer]);

  }
  @action
  focusOut() {
    if (event.keyCode === 13 || event.keyCode === 9) {
      this.answerQuestion(this.question, this.value, this.answer)
    } else {

      return;
    }

  }
  @action
  answerQuestion(question, option, answer) {
    this.onInfo(``);

    option = parseFloat(option.replace(',', '.'));
    if (!isEmpty(question.min)) {
      if (
        option < question.min ||
        option > question.max
      ) {
        this.set('error', `Der Wert sollte zwischen ${question.min} und ${question.max} liegen!`);
        //this.focusInput();
        return false;
      } else if (!/^\d*.\d*$/.test(option.toString())) {
        this.set('error', `Der Wert sollte eine Zahl zwischen ${question.min} und ${question.max} sein!`);
        //this.focusInput();
        return false;
      } else {

        this.set('error', null);
      }
    }
    if (!isEmpty(question.check)) {
      if (
        option >= parseInt(question.check.min.min) &&
        option <= parseInt(question.check.min.max)
      ) {
        this.onInfo(`Richtige Eingabe?`);
      }
      if (
        option >= parseInt(question.check.max.min) &&
        option <= parseInt(question.check.max.max)
      ) {
        this.onInfo(`Richtige Eingabe?`);
      }
    }

    this.onClose();
    console.log(question, option.toString(), answer)

    this.onAnswer(question, option.toString(), answer);
    return false;
  }
};
