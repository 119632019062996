import Route from "@ember/routing/route";
import { inject as service } from "@ember-decorators/service";

export default class ExportRoute extends Route {
  @service()
  firebaseApp;
  async model() {

  }
}
