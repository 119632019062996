import Route from "@ember/routing/route";
import { inject as service } from "@ember-decorators/service";
import { action } from "@ember-decorators/object";
import moment from "moment";
export default class Dashboard extends Route.extend({
  // anything which *must* be merged to prototype here
}) {
  @service()
  router;

  @service()
  firebaseApp;

  @action
  willTransition() {
    this.controller.set("offCanvas", false);
  }

  groupSorting = [
    "1",
    "10",
    "12",
    "7",
    "14",
    "9",
    "13",
    "5",
    "11",
    "6",
    "2",
    "8",
    "3",
    "4",
    "17",
    "15",
    "16",
  ];

  async model() {
    try {
      let reports, report, user, users, filter, canCreateNewReport;
      const appMandate = this.modelFor("application");

      const auth = await this.firebaseApp.auth();
      const currentUser = auth.currentUser;
      console.log("#1", currentUser);
      const number = window.localStorage.getItem("nummer");

      if (currentUser !== null) {
        filter = {
          email: currentUser.email,
        };
      } else if (number !== null) {
        filter = {
          nummer: number,
        };
      } else {
        filter = {
          authtoken: window.localStorage.getItem("token"),
        };
      }
      users = await this.store.query("user", {
        filter: filter
      });
      if (users.length > 0) {
        user = users.firstObject;
      } else {
        user = null;
        this.router.transitionTo("account");
      }

      if (user === null) {
        return;
      }
      console.log(appMandate);
      let questions = await this.store.query("question", {
        orderBy: {
          sorting: "asc",
        },
      });

      questions = questions.filter((i) => {
        if (appMandate.level === "basic") {
          return i.level === appMandate.level;
        }
        if (appMandate.level === "premium") {
          return i.level === "premium" || i.level === "basisplus" || i.level === "basic";
        }
        if (appMandate.level === "basisplus") {
          return i.level === "basisplus" || i.level === "basic";
        }
      });

      reports = await this.store.query("report", {
        filter: {
          user: user.id,
        },
      });
    
      reports = reports.sortBy("sortCreated").reverse();
      
      if (reports.length > 0) {
        report = reports.firstObject;

        let answers = await report.get("answers");
        
        let oldReportDate = moment(report.created);
        console.log(oldReportDate);
        oldReportDate = oldReportDate.diff(moment(), "months");
        console.log(oldReportDate);
        if (oldReportDate >= 10) {
          canCreateNewReport = true;
          report = await this.store
            .createRecord("report", {
              user: user,
              created: new Date(),
            })
            .save();
        }
      } else {
       report = await this.store
          .createRecord("report", {
            user: user,
            created: new Date(),
          })
          .save();
      }

      let sortedQuestions = [];
      questions = questions.filter((item) => {
        if (item.onlygender === undefined) {
          return true;
        }
        if (item.onlygender === user.gender) {
          return true;
        } else {
          return false;
        }
      });
      if (appMandate.get('snippet') == 'aok' || appMandate.get('snippet') == 'arburg') {
        questions = questions.filter((item) => {
          if (item.field === "HBA1C") {
            return false;
          } 
          return true;
        });
      }

      this.groupSorting.forEach((item) => {
        sortedQuestions.pushObjects(questions.filterBy("group", item));
      });
      let temp = [];

      return {
        user: user,
        project: appMandate,
        questions: sortedQuestions,
        report: report,
        groupSorting: this.groupSorting,
        canCreateNewReport: canCreateNewReport,
      };
    } catch (e) {
      console.error(e);
    }
  }
}
