import Controller from '@ember/controller';
import {
  action,
  computed
} from '@ember-decorators/object';
import moment from 'moment';
import { inject as service } from "@ember-decorators/service";

export default class DashboardBerichteDetailController extends Controller {

  @service()
  router;

  @service()
  firebaseApp;

  @computed("model") 
  get createNew() {
    return moment(this.model.lastObject.created).diff(moment(), "months") <= -6;
  }
  @action
  async createNewReport() {
    const auth = await this.firebaseApp.auth();
    const currentUser = auth.currentUser;
    console.log("#1", currentUser);
    const number = window.localStorage.getItem("nummer");
    let filter, users, user, report;
    if (currentUser !== null) {
      filter = {
        email: currentUser.email,
      };
    } else if (number !== null) {
      filter = {
        nummer: number,
      };
    } else {
      filter = {
        authtoken: window.localStorage.getItem("token"),
      };
    }
    users = await this.store.findAll("user");
    if (users.length > 0) {
      user = users.firstObject;
    } else {
      user = null;
      this.router.transitionTo("account");
    }
    report = await this.store
            .createRecord("report", {
              user: user,
              created: new Date(),
            })
            .save();
            this.transitionToRoute('dashboard.uebersicht')
  }
  @action
  async pdf(report) {
    let reports = await this.get('model');
    let user = await report.user;

    let oldReportIndex = reports.indexOf(report);

    let oldinput;
    if (oldReportIndex <= reports.length) {
      const oldReport = await reports.objectAt(`${oldReportIndex+1}`)
      if (oldReport) {
        let data = await this.build(reports.objectAt(`${oldReportIndex+1}`));
        oldinput = data.input;
      }
 
    }
 
    let {input, linkArray, labor} = await this.build(report);


    let result = await fetch('https://vitabilanz-pdf.staging-netzreich.de/index.php?mode=1', {
      method: 'POST',
      headers: {
        'Accept': 'application/pdf',
        'Content-Type': 'application/pdf'
      },
      body: JSON.stringify({
        "user": window.localStorage.getItem('nummer'),
        "firstname": "",
        "lastname": "",
        "labor": labor >= 10 ? '1' : '0',
        "internet": user.online ? "1" : "0",
        "date": moment(report.created).format("DD.MM.YYYY"),
        "export": {},
        "links": linkArray,
        "answers": input,
        "oldreport": {
          "answers": oldinput
        }
      })
    });
    let blob = await result.blob();
    let newBlob = new Blob([blob], {
      type: "application/pdf",
    });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    let link = document.createElement("a");
    link.href = data;
    link.download = "Bericht.pdf";
    link.click();
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
    }, 100);
  };
  async build(report) {

    let answers = await report.get('answers');

    let user = await report.user;
    let input = [];
    let project;

    if (window.location.hostname == "localhost") {
      project = await this.store.query("project", {
        filter: {
          appurl: "meinevitabilanz.de",
        },
      });
    } else {
      project = await this.store.query("project", {
        filter: {
          appurl: window.location.hostname,
        },
      });
    }
    input.pushObjects([
      {
        id: "1",
        question: "GESCHLECHT",
        value: user.gender == "Männlich" ? "0" : "1",
      },
      {
        id: "1",
        question: "ALTER",
        value: user.realage.toString(),
      },
      {
        id: "1",
        question: "mandant",
        value: project.firstObject.snippet,
      },
    ]);
    await Promise.all(
      answers.map(async (element) => {
        let question = await element.question;
        if (question == null) {
          return;
        }
        let inputData = {
          id: "",
          question: question.field,
          value: element.answer,
        };
        return input.pushObject(inputData);
      })
    );
    console.log("INOUT LENGTH", input.length);
    input = input.uniqBy("question");
    console.log("INOUT LENGTH", input.length);

    let links = await this.store.findAll("link");
    let linkArray = links.toArray().map((item) => {
      return item.serialize();
    });

    let labor = input.filter((i) => {
      console.log(i.question);
      return [
        "NUECHTERNBZ",
        "GESAMTCHOLESTERIN",
        "HBA1C",
        "HDLCHOLESTERIN",
        "LDLCHOLESTERIN",
        "TRIGLYCERIDE",
        "HARNSAEURE",
        "KREATININ",
        "LEBERGGT",
        "LEBERGPT",
      ].includes(i.question);
    }).length;
    console.log("labor", labor);
    return {
      linkArray,
      labor,
      input
    }
  }
}
