import FirestoreAdapter from 'emberfire/adapters/firestore';


export default class ApplicationAdapter extends FirestoreAdapter {

    constructor() {
        super(...arguments);
    
    }
}
