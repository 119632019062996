import Component from '@ember/component';
import { action } from '@ember-decorators/object';
import {
  validatePresence,
  validateLength,
  validateConfirmation
} from 'ember-changeset-validations/validators';
import lookupValidator from 'ember-changeset-validations';
import { inject as service } from '@ember-decorators/service';
import { task } from 'ember-concurrency-decorators';


export default class AccountRegister extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  @service() router;
  @service() firebaseApp;
  @service() session;
  @service() store;
  @service() client;

  actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be whitelisted in the Firebase Console.
    url: 'http://localhost:4200/account/signup',
    // This must be true.
    handleCodeInApp: true,
  
  };

  showCode = true;

  isLoading = false;

  registerError = '';

  userRegister = {
    username: '',
    token: '',
    online: true,
    gender: '',
    nummer: '',
    age: "",
  };

  registerValidation = lookupValidator({
    gender: [
      validatePresence({ presence: true, message: 'Bitte Geschlecht angeben' })
    ],
    age: [
      validatePresence({ presence: true, message: 'Bitte Alter angeben' })
    ],

    nummer: [
      validatePresence({ presence: true, message: 'Bitte geben Sie den Code ein, welchen Sie von Ihrem Arzt erhalten haben' })
    ]
  });



  @task
  *register(userRegister) {
      try {
        if (userRegister.get('gender') === '') {
          this.set('error', "Bitte Geschlecht angeben");
          return;
        }
        if (userRegister.get('age') === '__') {
          this.set('error', "Bitte Alter angeben");
          return;
        }

        if (userRegister.get('isValid')) {
          userRegister.save();
        }
    
        this.set('isLoading', true);
        let authcodes = yield this.store.query('authtoken', {filter: {token: userRegister.get('token'), user: null}});
      
        if (authcodes.length > 0) {
          this.set('isLoading', false);
          this.set('registerError', "Patient existiert bereits");
  
        } else {
   
          let authtoken = yield this.store.createRecord('authtoken', {
            token: userRegister.get('nummer'),
            user: null
          }).save();
          console.log("CREATE USERRCONNECTIOn")
          let success = yield this.createNewPendingUser.perform(userRegister, authtoken);
        }
      } catch(e) {
        console.error(e);
      }
  }

  @task
  *createNewPendingUser(userRegister, authtoken) {
    let username = userRegister.get('username');
    let nummer = userRegister.get('nummer');
    if (!username) {
      username = `p-${nummer}@meinevitabilanz.de`;
    }

    moment().subtract(userRegister.get('age'), 'years').format("DD.MM.YYYY");

    let user = yield this.store.createRecord('user', {
      username: username,
      nummer: userRegister.get('nummer'),
      email: username,
      authtoken: authtoken,
      age: moment().subtract(userRegister.get('age'), 'years').format("DD.MM.YYYY"),
      gender: userRegister.get('gender'),
      online: userRegister.get('online')
    }).save();
    
    yield this.store.createRecord('userconnection', {
      user: userRegister.get('nummer'),
      clientname: this.client.client.name
    }).save();
    authtoken.set('user', user);
    authtoken.set('usedAt', new Date());
    yield authtoken.save();

    window.localStorage.setItem('token', authtoken.id)
    window.localStorage.removeItem('nummer');
    try {
      const auth = yield this.firebaseApp.auth();
      yield auth.signOut();
    } catch(e) {
      console.error(e);
    }



    this.router.transitionTo('dashboard.uebersicht');
  }
};
