import Controller from '@ember/controller';
import { service } from '@ember-decorators/service';
import { alias } from '@ember-decorators/object/computed';
import { computed, action } from '@ember-decorators/object';
import { htmlSafe } from '@ember/string';

export default class Dashboard extends Controller.extend({
  // anything which *must* be merged to prototype here
}) {

  videoModal = true;
  offCanvas = false;

  constructor() {
    super(...arguments);
    let video = window.localStorage.getItem('video');
    if (video === 'video') {
      this.set('videoModal', false);
    }
  }
  @action
  closeVideo() {
    this.set('videoModal', false);
    window.localStorage.setItem('video', 'video');
  }
  @action
  showVideo() {
    this.set('videoModal', true);
    window.localStorage.setItem('video', null);
  }

  @computed('answers', 'model.report.answers.[]') 
  get answersLength() {
   
    return this.model.report.answers.length;
  }
  @computed('answersLength', 'questions')
  get answersLengthInPercent() {
    let answers = this.get('model.report.answers');
    console.log(answers)
    let filteresAnswers = answers.toArray().filter(item=>{
      return item.group !== '16' && item.group !== '15';
    })
    
    let temp =  100/(110 - 16) * filteresAnswers.length ;
    let rounding =  Math.round(temp * 100) / 100;
    if (rounding > 100) {
      rounding = 100;
    }
    return 100;
  }

  @computed('answersLengthInPercent')
  get percentFactor() {
    return 180 - (this.answersLengthInPercent * 3.6) * 1;
  }
}

