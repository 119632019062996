import DS from 'ember-data';
const { Serializer } = DS;

export default class ObjectTransform extends DS.Transform {
  deserialize(value) {
    if (!Ember.$.isPlainObject(value)) {
      return {};
    } else {
      return value;
    }
  }
  serialize(value) {
    if (!Ember.$.isPlainObject(value)) {
      return {};
    } else {
      return value;
    }
  }
}
