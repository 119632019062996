import DS from 'ember-data';
const { Model } = DS;
import { attr, hasMany, belongsTo } from '@ember-decorators/data';
import { computed } from '@ember-decorators/object';
import { isEmpty } from '@ember/utils';
import moment from 'moment';

export default class UserModel extends Model {
  @attr('string') sub;
  @attr('string') role;
  @attr('string') username;
  @attr('string') firstname;
  @attr('string') lastname;
  @attr('string') email;
  @attr('string') sms;

  @computed('email')
  get loginemail() {
    if (isEmpty(this.get('username'))) {
      return `p-${this.get('nummer')}@meinevitabilanz.de`;
    } else {
      return this.get('username');
    }
  }

  @attr('string') handy;
  @attr('boolean') online;
  @attr('boolean', {defaultValue: false}) isregistered;

  @attr('string') plz;
  @attr('string') city;
  @attr('string') street;
  @attr('string') clientemail;
  @attr('boolean') dataprivacy;
  @attr('boolean') mailfromvita1;
  @attr('boolean') mailfromvita2;
  @attr('boolean') mailfromvita3;

  @attr('string') age;
  @attr('string') gender;
  @attr('string') nummer;
  @belongsTo('authtoken') authtoken;
  
  @computed('age')
  get realage() {
    let alter = moment(this.get('age'), "DD.MM.YYYY");
    return moment().diff(alter, 'years')
  }

  @computed('sub')
  get isRegistered() {
    return !isEmpty(this.sub);
  }

  @hasMany('report') reports;
}
