import DS from 'ember-data';
const { Serializer } = DS;
import { A, isArray } from '@ember/array';

export default class ArrayTransform extends DS.Transform {
  deserialize(value) {
    if (isArray(value)) {
      return  A(value);
    } else {
      return  A();
    }
  }
  serialize(value) {
    if (isArray(value)) {
      return  A(value);
    } else {
      return  A();
    }
  }
}
