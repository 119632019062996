// app/serializers/schedule.js
import FirebaseSerializer from 'emberfire/serializers/firestore';

export default FirebaseSerializer.extend({
  attrs: {
    link: {
      embedded: 'always'
    },
    location: {
      embedded: 'always'
    }
  }
});
