import EmberRouter from '@ember/routing/router';
import config from './config/environment';

const Router = EmberRouter.extend({
  location: config.locationType,
  rootURL: config.rootURL
});

Router.map(function() {
  this.route('account', function() {
    this.route('login');
    this.route('logout');
    this.route('register');
    this.route('reg');
    this.route('passwortvergessen');
    this.route('signup');
    this.route('admin');
  });
  this.route('support', function() {
    this.route('impressum');
    this.route('datenschutz');
  });
  this.route('dashboard', function() {
    this.route('uebersicht');
    this.route('detail', {path: '/detail/:report_id'});
    this.route('news');
    this.route('faq');
    this.route('berichte', function() {
      this.route('detail', {path: '/detail/:report_id'});
    });
  });
  this.route('seed');
  this.route('lexicon');
  this.route('export');
});

export default Router;
