import Component from "@ember/component";
import { action } from "@ember-decorators/object";
import { tagName, classNames } from "@ember-decorators/component";
import { inject as service } from "@ember-decorators/service";
import { task } from "ember-concurrency-decorators";

@classNames("component-login")
export default class AccountLogin extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  @service()
  router;

  @service()
  store;

  @service()
  firebaseApp;

  loginPromise;

  quickDto = {
    nummer: "",
  };

  loginDto = {
    username: "",
    password: "",
  };

  isLoading = false;

  @task
  *loginWithNumber(quickDto) {
    try {
      window.localStorage.removeItem("nummer");
      this.set("isLoading2", true);

      let users = yield this.store.query("user", {
        filter: {
          nummer: quickDto.nummer,
        },
      });
      if (!users.length > 0) {
        this.set("quickError", "Kein Benutzer mit dieser Nummer gefunden!");
        this.set("isLoading2", false);
        return;
      }

      window.localStorage.setItem("nummer", quickDto.nummer);

      this.set("isLoading", false);
      this.router.transitionTo("dashboard.uebersicht");
    } catch (e) {
      console.error(e);
    }
  }

  @task
  *login(loginData) {
    try {
      this.set("isLoading", true);
      const auth = yield this.firebaseApp.auth();

      let result = yield auth.signInWithEmailAndPassword(
        loginData.username,
        loginData.password
      );

      if (result) {
        this.set("isLoading", false);
        this.router.transitionTo("dashboard.uebersicht");
      }
    } catch (e) {
      console.error(e);
    }
  }
}
