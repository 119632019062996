import Component from '@ember/component';
import { action } from '@ember-decorators/object';


export default class AppQuestionaireQuestionMultiselectComponent extends Component {
    isSelected = false;

    answerBuffer = [];

    didReceiveAttrs() {
      if (this.answer == null) {
        return;
      }
      this.answer.answer.split(', ').forEach(element => {
        this.answerBuffer.addObject(element);
      });
      

      if (this.answer.answer === this.option) {
        this.isSelected = true;
      }

    }
  
    @action
    nothing() {}
  
    @action
    answerQuestion(question, option, answer) {
      //this.onClose();
      this.onAnswer(question, option, answer);
      return false;
    }

    @action
    onAnswerMultipleProxy(question, option, answer) {
        console.log('check', ...arguments);
        if (!this.answerBuffer.includes(option)) {
            this.answerBuffer.addObject(option);
        } else {
            this.answerBuffer.removeObject(option);
        }
        console.log('answer is', this.answerBuffer.join(', '))
        this.send('answerQuestion', question, this.answerBuffer.join(', '), answer);
    }
}
