import Route from "@ember/routing/route";
import fetch from "fetch";
import moment from "moment";

export default class DashboardBerichteDetailRoute extends Route {
  async model(params) {
    let report = await this.store.findRecord("report", params.report_id);
    let reports = await this.modelFor('dashboard/berichte');
    let user = await report.user;
    let oldinput;
    let oldReportIndex = reports.indexOf(report);

    if (oldReportIndex <= reports.length) {
      const oldReport = await reports.objectAt(`${oldReportIndex+1}`)
      if (oldReport) {
        let data = await this.build(reports.objectAt(`${oldReportIndex+1}`));
        oldinput = data.input;
      }
 
    }
    

    let {input, linkArray, labor} = await this.build(report);


    let result = await fetch(
      "https://vitabilanz-pdf.staging-netzreich.de/index.php?mode=0",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user: window.localStorage.getItem("nummer"),
          firstname: report.user.firstname,
          lastname: report.user.lastname,
          date: moment(report.created).format("DD.MM.YYYY"),
          labor: labor >= 10 ? "1" : "0",
          internet: user.online ? "1" : "0",
          export: {},
          links: linkArray,
          answers: input.uniqBy("question"),
          oldreport: {
            answers: oldinput,
          }
        }),
      }
    );
    return result.json();
  }

  async build(report) {

    let answers = await report.get('answers');

    let user = await report.user;
    let input = [];
    let project;

    if (window.location.hostname == "localhost") {
      project = await this.store.query("project", {
        filter: {
          appurl: "meinevitabilanz.de",
        },
      });
    } else {
      project = await this.store.query("project", {
        filter: {
          appurl: window.location.hostname,
        },
      });
    }
    input.pushObjects([
      {
        id: "1",
        question: "GESCHLECHT",
        value: user.gender == "Männlich" ? "0" : "1",
      },
      {
        id: "1",
        question: "ALTER",
        value: user.realage.toString(),
      },
      {
        id: "1",
        question: "mandant",
        value: project.firstObject.snippet,
      },
    ]);
    console.log(report, answers);
    await Promise.all(
      answers.map(async (element) => {
        let question = await element.question;
        if (question === null) return;
        let inputData = {
          id: "",
          question: question.field,
          value: element.answer,
        };
        return input.pushObject(inputData);
      })
    );

    console.log("INOUT LENGTH", input.length);
    
    console.log("INOUT LENGTH", input.length);

    let links = await this.store.findAll("link");
    let linkArray = links.toArray().map((item) => {
      return item.serialize();
    });

    let labor = input.filter((i) => {
      console.log(i.question);
      return [
        "NUECHTERNBZ",
        "GESAMTCHOLESTERIN",
        "HBA1C",
        "HDLCHOLESTERIN",
        "LDLCHOLESTERIN",
        "TRIGLYCERIDE",
        "HARNSAEURE",
        "KREATININ",
        "LEBERGGT",
        "LEBERGPT",
      ].includes(i.question);
    }).length;
    console.log("labor", input);
    input = input.uniqBy("question");
    return {
      linkArray,
      labor,
      input
    }
  }
}
