import Route from '@ember/routing/route';
import {
  inject as service
} from '@ember-decorators/service';

export default class AccountSignupRoute extends Route {

  @service() firebaseApp;

  async model() {
    
    
  }
}
