import Route from '@ember/routing/route';
import {
  inject as service
} from '@ember-decorators/service';
export default class AccountLogout extends Route.extend({
  // anything which *must* be merged to prototype here
}) {

  @service() firebaseApp;

  async beforeModel() {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('nummer');
    window.localStorage.removeItem('session');
    window.localStorage.removeItem('access_token');
    const auth = await this.firebaseApp.auth();
    await auth.signOut();
    return window.location.reload();
  }
}
