import DS from "ember-data";
const { Model } = DS;
import { belongsTo, attr } from "@ember-decorators/data";

export default class AnswerModel extends Model {
  @belongsTo("question") question;
  @attr("string") text;
  @attr("boolean") opened;
  @attr("string") answer;
  @belongsTo("user") user;
  @belongsTo("report") report;
}
