import Component from "@ember/component";
import { computed, action, observes } from "@ember-decorators/object";
import { defineProperty } from "@ember/object";
import { tagName } from "@ember-decorators/component";
import { inject as service } from "@ember-decorators/service";
import { task } from "ember-concurrency-decorators";
import { isEmpty } from "@ember/utils";
import { run } from "@ember/runloop";
import AnswerService from "../../answer/service";

//t = current time
//b = start value
//c = change in value
//d = duration
Math.easeInOutQuad = function (t, b, c, d) {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};

@tagName("div")
export default class AppQuestionnaireQuestion extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  @service()
  store;
  @service('answer')
  answerService;

  classNameBindings = ["wasOpened"];

  isOpen = false;

  @observes("isOpen")
  onIsOpen() {
    console.log("onIsOpen called", this.answer);
    let status =
      this.get("answer.opened") && isEmpty(this.get("answer.answer"));
    this.set("wasOpened", status);
  }

  answer = null;

  info = "";

  scrollTo(element, to, duration) {
    let start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    let animateScroll = function () {
      currentTime += increment;
      let val = Math.easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  }

  @action
  setInfo(info) {
    this.set("info", info);
  }

  @action
  nichtBerufstaetigAction() {
    this.answerService.trigger("Berufstaetig");
  }

  async onNichtBerufstaetig() {
    if (
      [
        "JOB_LIKE",
        "JOB_ZUKUNFT",
        "JOB_LEISTUNG",
      ].includes(this.question.field)
    ) {
      console.log("HIER HIER HIER", this.question, this.question.options.get(2))
      this.onAnswerProxy(this.question, this.question.options.get(2), await this.get('getanswer'), false)
    }

  }

  willDestroy() {
    this.answerService.off("Berufstaetig", this, "onNichtBerufstaetig");
  }

  async didReceiveAttrs() {
    try {
      this.get("getanswer");
      this.answerService.on("Berufstaetig", this, "onNichtBerufstaetig");
      //this.reloadAnswer.perform();
      if (isEmpty(this.nextQuestion)) {
        return;
      }
      if (
        this.get("nextQuestion.id") === this.get("question.id") &&
        this.get("isOpen") === false
      ) {
        this.send("open");
      }
    } catch (e) {
      console.error(e);
    }
  }

  @observes("nextQuestion")
  onNextQuestionChangedObserver() {

    if (
      this.get("nextQuestion.id") === this.get("question.id") &&
      this.get("isOpen") === false &&
      !this.answer
    ) {
      this.set("isOpen", true);
    }

    return null;
  }

  @computed("report.get('answers').[]")
  get getanswer() {
    return this.report.get('answers').then((answers) => {
      let answer = answers.find((item, index, array) => {
        return item.question.get("id") === this.question.get("id");
      });

      if (answer === undefined) {
        this.onFirstQuestionWithoutAnswer(this.question);
        return;
      }
      this.set("answer", answer);
    });
  }

  @action
  onAnswerProxy(question, option, answer) {
    if (window.innerWidth < 500) {
      this.scrollTo(
        document.getElementsByClassName("container-scroll")[0],
        this.element.offsetTop,
        500
      );
    }
    let tempanswer = {
      question: question,
      text: question.questiontext,
      answer: option,
      report: this.report,
      user: this.user,
    };
    if (answer === undefined || answer === null) {
      answer = this.store.createRecord("answer", tempanswer);
    }
    this.set("answer", answer);
    //this.reloadAnswer.perform();
    if (this.isLast) {
      this.openNextGroup(this.activeGroup);
    }
    
    this.onAnswer(question, option, answer);
  }

  @action
  open() {
    
    run.next(() => {
      this.set("isOpen", true);
    });

    return false;
  }

  @action
  close() {
    this.set("isOpen", false);
  }
}
