import Component from '@ember/component';
import {
  computed,
  action
} from '@ember-decorators/object';
import {
  run
} from '@ember/runloop';
import {
  set
} from '@ember/object';
import {
  className
} from '@ember-decorators/component';


export default class SidebarNavComponent extends Component {


  @className
  show = false;

  isInitialized = false;


  didInsertElement() {

    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual';
    }

    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', (e) => {
        this.set('scrollTop', document.getElementsByClassName('container-scroll')[0].scrollTop)

      });
    });

    const TableOfContents = {
      container: document.querySelector('.sidebar-nav'),
      links: null,
      headings: null,
      intersectionOptions: {
        rootMargin: '0px',
        threshold: 1
      },
      previousSection: null,
      observer: null,

      init() {
        this.handleObserver = this.handleObserver.bind(this)

        this.setUpObserver()
        this.findLinksAndHeadings()
        this.observeSections()


        this.links.forEach(link => {
          link.addEventListener('click', this.handleLinkClick.bind(this))
        })
      },


      handleLinkClick(evt) {
        evt.preventDefault()
        let id = evt.target.getAttribute('href').replace('#', '')

        let section = this.headings.find(heading => {
          return heading.getAttribute('id') === id
        })

        section.setAttribute('tabindex', -1)
        section.focus()

        window.scroll({
          behavior: motionQuery.matches ? 'instant' : 'instant',
          top: section.offsetTop - 15,
          block: 'start'
        })

        if (this.container.classList.contains('active')) {
          this.container.classList.remove('active')
        }
      },

      handleObserver(entries, observer) {
        entries.forEach(entry => {
          let href = `#${entry.target.getAttribute('id')}`,
            link = this.links.find(l => l.getAttribute('href') === href)


          if (entry.isIntersecting && entry.intersectionRatio >= 1) {
            link.classList.add('is-visible')
            this.previousSection = entry.target.getAttribute('id')
          } else {
            link.classList.remove('is-visible')
          }

          this.highlightFirstActive()
        })
      },

      highlightFirstActive() {
        let firstVisibleLink = this.container.querySelector('.is-visible')

        this.links.forEach(link => {
          link.classList.remove('active')
        })

        if (firstVisibleLink) {
          firstVisibleLink.classList.add('active')
        }

        if (!firstVisibleLink && this.previousSection) {
          this.container.querySelector(
            `a[href="#${this.previousSection}"]`
          ).classList.add('active')
        }
      },

      observeSections() {
        this.headings.forEach(heading => {
          console.log(heading);
          if (heading === null) {
            return;
          }
          this.observer.observe(heading)
        })
      },

      setUpObserver() {
        this.observer = new IntersectionObserver(
          this.handleObserver,
          this.intersectionOptions
        )
      },

      findLinksAndHeadings() {
        this.links = [...this.container.querySelectorAll('a')]
        this.headings = this.links.map(link => {
          let id = link.getAttribute('href')
          return document.querySelector(id)
        })
      }
    }


    TableOfContents.init()


    let eventlistener = (e) => {


    }
    document.getElementsByClassName('container-scroll')[0].addEventListener('scroll', eventlistener);

    window.onpopstate = (event) => {
      event.preventDefault();
      let hash = window.location.hash.substr(1);

      if (hash === '') {

        run.next(() => {
          document.getElementsByClassName('container-scroll')[0].scrollTop = this.get('scrollTop');
          document.getElementsByClassName('container-scroll')[0].addEventListener('scroll', eventlistener);
        })
      } else {


        run.next(() => {

          let elem = document.getElementById(hash);

          this.set('show', false);
          elem.scrollIntoView({
            left: 0,
            block: 'start',
            behavior: 'instant'
          });
          document.getElementsByClassName('container-scroll')[0].addEventListener('scroll', eventlistener);
        })
      }


    };
  }

  @action
  showDropdown() {
    console.log("toggle sidebar")
    this.toggleProperty('show');
  }

  @computed('index')
  get navpoints() {
    let points = [];
    for (let prop in this.index) {
      let data = this.index[prop];
      data.id = prop;
      if (data.h2) {
        let h2s = [];
        for (let prop2 in data.h2) {
          data.h2[prop2] = {
            id: prop2,
            title: data.h2[prop2]
          }
          h2s.pushObject(data.h2[prop2]);
        }
        data.h2 = h2s;
      }
      points.pushObject(data);
    }
    let lexicon = {
      h1: "Lexikon",
      id: "lexikon",
      h2: []
    };

    for (let prop in this.lexiconIndex) {
      let data = this.lexiconIndex[prop];
      data.id = prop;
      data.h2 = data.h1;
      data.title = data.h1;

      lexicon.h2.pushObject(data);
    }
    points.addObject(lexicon);
    console.log(points)

    return points;
  }

  @action
  scrollTo(target) {

    this.set('show', false);
    if (history.pushState) {
      history.pushState(null, null, `#${target}`);
    } else {
      window.location.hash = `#${target}`;
    }
    console.log("do the scrolling")
    let elem = document.getElementById(target);
    elem.scrollIntoView({
      left: 0,
      block: 'start',
      behavior: 'instant'
    });
  }
}
