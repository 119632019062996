import DS from 'ember-data';
const { Model } = DS;
import { belongsTo, hasMany, attr } from '@ember-decorators/data';
import { computed } from '@ember-decorators/object';
import moment from 'moment';

export default class ReportModel extends Model {
  @belongsTo('user') user;
  @belongsTo('file') export;
  @attr('string') created;
  @hasMany('answer', { embedded: true }) answers;

  @computed('created')
  get until() {
    return (async ()=>{
      return moment(this.created).add(31, 'days').format('DD.MM.YYYY');

    })();
  }

  @computed('created')
  get sortCreated() {
    return moment(this.created).unix();
  }

  @computed('created')
  get until2() {
    return (async ()=>{
      return moment(this.created).add(31, 'days');

    })();
  }
  @computed('created')
  get year() {
    return moment(this.created).year();
  }

  @computed('answers.[]')
  get completed() {
    return (async ()=>{
      let answers = await this.get('answers');
      let filteresAnswers = answers.toArray().filter(item=>{
        return item.group !== '16' && item.group !== '15';
      })
      
      return filteresAnswers.length >= (110 - 16);
    })();
  }
  @computed('answers.[]')
  get progress() {
    return (async ()=>{
      let answers = await this.get('answers');
      console.log("COMPUTED PROGRESS WITH ", answers.length)
      let filteresAnswers = answers.toArray().filter(item=>{
        return item.group !== '16' && item.group !== '15';
      })
      
      let temp =  100/(110 - 16) * filteresAnswers.length ;
      return Math.round(temp * 100) / 100
    })();
  }

}
