import DS from "ember-data";
const { Model } = DS;
import { attr, hasMany } from "@ember-decorators/data";

export default class QuestionModel extends Model {
  @attr("string") grouptitle;
  @attr("string") questiontext;
  @attr("string") field;
  @attr("string") type;
  @attr("object") textblock;
  @attr("object") check;
  @attr("string") group;
  @attr("string") rendertype;
  @attr("number") sorting;
  @attr("array") options;
  @attr("number") min;
  @attr("number") max;
  @attr("string") level;
  @attr("string") unit;
  @attr("string") onlygender;
}
