define("ember-fire/components/async-await", ["exports", "ember-async-await-helper/components/async-await"], function (_exports, _asyncAwait) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _asyncAwait.default;
    }
  });
});
