export default function () {
  // Add your transitions here, like:
  this.transition(
    this.fromRoute('account.login'),
    this.toRoute('account.register'),
    this.use('fade'),
    this.reverse('fade'),
  );
  this.transition(
    this.fromRoute('account'),
    this.toRoute('dashboard'),
    this.use('toLeft'),
    this.reverse('fade'),
  );
  this.transition(
    this.fromRoute('dashboard.uebersicht'),
    this.toRoute('dashboard.berichte'),
    this.use('toLeft'),
    this.reverse('toRight'),
  );
  this.transition(
    this.fromRoute('dashboard.uebersicht'),
    this.toRoute('dashboard.news'),
    this.use('toLeft'),
    this.reverse('toRight'),
  );
  this.transition(
    this.fromRoute('dashboard.uebersicht'),
    this.toRoute('dashboard.berichte'),
    this.use('toLeft'),
    this.reverse('toRight'),
  );
  this.transition(
    this.fromRoute('dashboard.uebersicht'),
    this.toRoute('dashboard.faq'),
    this.use('toLeft'),
    this.reverse('toRight'),
  );
  this.transition(
    this.fromRoute('dashboard.berichte'),
    this.toRoute('dashboard.news'),
    this.use('toLeft'),
    this.reverse('toRight'),
  );
  this.transition(
    this.fromRoute('dashboard.berichte'),
    this.toRoute('dashboard.faq'),
    this.use('toLeft'),
    this.reverse('toRight'),
  );
  this.transition(
    this.fromRoute('dashboard.berichte'),
    this.toRoute('dashboard.uebersicht'),
    this.use('toRight'),
    this.reverse('toLeft'),
  );

  this.transition(
    this.fromRoute('dashboard.news'),
    this.toRoute('dashboard.faq'),
    this.use('toLeft'),
    this.reverse('toRight'),
  );
  this.transition(
    this.fromRoute('dashboard.news'),
    this.toRoute('dashboard.berichte'),
    this.use('toRight'),
    this.reverse('toLeft'),
  );
  this.transition(
    this.fromRoute('dashboard.news'),
    this.toRoute('dashboard.berichte'),
    this.use('toRight'),
    this.reverse('toLeft'),
  );

}
